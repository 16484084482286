<template>
  <div>
    <b-loading v-model="is_loading"/>
    <div
      v-if="!is_loading && CmsData.factory"
      class="is-flex is-flex-direction-column has-background-white px-5 py-5"
    >
      <div>
        <span class="is-size-6 has-text-grey mb-5">Введите данные</span>
      </div>
      <b-field
        label="Компания"
        class="mb-3"
      >
        <b-select
          placeholder="Выберите компанию"
          v-model="CmsData.factory.branch.company.id"
          required
        >
          <option
            v-for="option of Company_list"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field
        label="Филиал"
        class="mb-3"
      >
        <b-select
          placeholder="Выберите филиал"
          v-model="CmsData.factory.branch.id"
          required
        >
          <option
            v-for="option of CompanyBranches"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field
        label="Завод"
        class="mb-3"
      >
        <b-select
          placeholder="Выберите завод"
          v-model="CmsData.factory.id"
          required
        >
          <option
            v-for="option of BranchFactories"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Пользователи">
        <b-taginput
          v-model="UsersData"
          ref="taginput"
          :data="filteredTags"
          autocomplete
          :allow-new="false"
          :open-on-focus="true"
          field="first_name"
          placeholder="Выберите пользователя"
          @typing="getFilteredTags"
          @input="handleValue"
        >
          <template v-slot="props">
            {{ props.option.last_name || '' }} {{ props.option.first_name || '' }}
          </template>
          <template #selected="props">
            <b-tag
              v-for="(tag, index) in props.tags"
              :key="index"
              :tabstop="false"
              ellipsis
              closable
              @close="$refs.taginput.removeTag(index, $event)"
            >
              {{ tag.last_name || '' }} {{ tag.first_name || '' }}
            </b-tag>
          </template>
        </b-taginput>
      </b-field>

      <b-field
        class="control mt-4"
        label="Наименование контролируемого оборудования"
      >
        <b-input
          type="text"
          v-model="CmsData.device_name"
          placeholder="Наименование контролируемого оборудования"
          required
          :message="errors && errors['device_name']"
          :type="errors && errors['device_name'] && 'is-danger'"
        />
      </b-field>

      <b-field
        class="mt-4"
        label="IP адрес CMS (например: 5.251.157.236)"
        :message="errors && errors['ip']"
        :type="errors && errors['ip'] && 'is-danger'"
      >
        <b-input
          type="text"
          v-model.trim="CmsData.ip"
          placeholder="IP адрес CMS"
          required
        />
      </b-field>

      <b-field
        class="mt-4"
        label="IP адрес IDM-Edge (например: 5.251.157.236)"
        :message="errors && errors['ip_local']"
        :type="errors && errors['ip_local'] && 'is-danger'"
      >
        <b-input
          type="text"
          v-model="CmsData.ip_local"
          placeholder="IP адрес IDM-Edge"
          required
        />
      </b-field>

      <b-field
        class="mt-4"
        label="Время синхр. (например: 02)"
        :message="errors && errors['sync_hour']"
        :type="errors && errors['sync_hour'] && 'is-danger'"
      >
        <b-input
          type="text"
          v-model="CmsData.sync_hour"
          placeholder="Часы"
          required
        />
      </b-field>

      <b-field
        class="mt-4"
        label="Время синхр. (например: 30)"
        :message="errors && errors['sync_minute']"
        :type="errors && errors['sync_minute'] && 'is-danger'"
      >
        <b-input
          type="text"
          v-model="CmsData.sync_minute"
          placeholder="Минуты"
          required
        />
      </b-field>

      <b-field
        class="mt-4"
        label="Логин CMS"
      >
        <b-input
          type="text"
          v-model="CmsData.login"
          placeholder="Логин CMS"
          required
        />
      </b-field>

      <b-field
        class="mt-4 mb-4"
        label="Пароль CMS"
      >
        <b-input
          type="text"
          v-model="CmsData.password"
          placeholder="Пароль CMS"
          required
        />
      </b-field>

      <b-field label="Параметры VIB-ов (количество каналов)">
        <!--      <b-select-->
        <!--        placeholder="Выберите количество"-->
        <!--        v-model="CmsData.module_count"-->
        <!--        required-->
        <!--      >-->
        <!--        <option-->
        <!--          v-for="option in module_count"-->
        <!--          :value="option.id"-->
        <!--          :key="option.id"-->
        <!--        >-->
        <!--          {{ option.name }}-->
        <!--        </option>-->
        <!--      </b-select>-->
        <b-input
          type="number"
          v-model="CmsData.module_count"
          placeholder="Количество каналов"
          required
        />
      </b-field>

      <b-field label="Параметры температуры (количество каналов)">
        <!--      <b-select-->
        <!--        placeholder="Выберите количество"-->
        <!--        v-model="CmsData.temperature_count"-->
        <!--        required-->
        <!--      >-->
        <!--        <option-->
        <!--          v-for="option in temperature_count"-->
        <!--          :value="option.id"-->
        <!--          :key="option.id"-->
        <!--        >-->
        <!--          {{ option.name }}-->
        <!--        </option>-->
        <!--      </b-select>-->
        <b-input
          type="number"
          v-model="CmsData.temperature_count"
          placeholder="Количество каналов"
          required
        />
      </b-field>
      <b-field
        label="Сдвиг"
      >
        <!--      :message="errors && errors['shift']"-->
        <!--      :type="errors && errors['shift'] && 'is-danger'"-->
        <b-input
          type="number"
          v-model="CmsData.shift"
          placeholder="shift"
          required
        />
      </b-field>
      <b-field
        label="Коэффициент"
        :message="errors && errors['coefficient']"
        :type="errors && errors['coefficient'] && 'is-danger'"
      >
        <b-input
          type="number"
          step="0.01"
          v-model="CmsData.coefficient"
          placeholder="coefficient"
          required
        />
      </b-field>

      <b-field label="Конфигурация">
        <section>
          <b-field><b-switch v-model="isTrendsActive" type="is-info">Тренды</b-switch></b-field>
          <b-field><b-switch v-model="isSpectersActive" type="is-info">Спектры</b-switch></b-field>
          <b-field><b-switch v-model="isTrendSpectersActive" type="is-info">Тренды полос спектров</b-switch></b-field>
          <b-field><b-switch v-model="isOilsActive" type="is-info">Тренды счетчиков частиц в масле</b-switch></b-field>
          <b-field><b-switch v-model="isDKWActive" type="is-info">Тренды DKW</b-switch></b-field>
          <b-field><b-switch v-model="isAIActive" type="is-info">Интеграция AI</b-switch></b-field>
        </section>
      </b-field>

      <b-field
        v-if="CmsData.image_path"
        label="Изображение"
      >
        <b-image
          :src="getImagePath(CmsData.image_path)"
          style="width: 300px"
          alt="A random image"
        />
      </b-field>

      <b-field
        class="mt-5 is-flex is-flex-direction-column is-align-items-center py-5"
        label="Загрузка изображения"
      >
        <b-upload
          v-if="!result.image"
          drag-drop
          accept="image/*"
          @input="handleImageCrop"
        >
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon
                  icon="upload"
                  size="is-medium"
                >
                </b-icon>
              </p>
              <p>Нажмите или перетащите файл для загрузки</p>
            </div>
          </section>
        </b-upload>
        <div
          v-else
          class="has-text-centered"
        >
          <Preview
            :width="200"
            :height="200"
            :image="result.image"
            :coordinates="result.coordinates"
          />
          <b-button
            type="is-danger is-light mt-3"
            @click="deleteImage"
          >Удалить
          </b-button>
        </div>
      </b-field>

      <b-message v-if="unloadErrors.length > 0" type="is-danger">
        <p>Ошибки выгрузки конфигурации</p>
        <ul style="list-style: disc" class="ml-5">
          <li v-for="error in unloadErrors" :key="error">{{ error }}</li>
        </ul>
      </b-message>

      <div class="is-flex is-flex-wrap-wrap mt-4" style="gap: 1rem">
        <button
          class="button is-info"
          @click="SaveChangesCMS"
        >Сохранить
        </button>
        <button class="button is-warning" :disabled="status > 1" @click="onStatusButtonClick">
          {{ getStatusButtonLabel(status) }}
        </button>
        <button
          class="button is-danger"
          @click="DeleteAgent"
        >Удалить
        </button>
        <button class="button is-danger" @click="changeStatus(3)">Получить с устройства</button>
        <button class="button is-primary" @click="copyDialogIsVisible = true">Дублировать конфигурации</button>
        <b-button class="button is-info" :loading="isUnloading" @click="unloadConfiguration">Отправить в устройство</b-button>
      </div>
      <SelectCMS :is-visible.sync="copyDialogIsVisible" :agent-id="cms_id" @refresh="reloadPage"/>
      <b-modal
        v-model="cropperModal"
        has-modal-card
        :can-cancel="false"
        @close="handleCropperModalClose"
      >
        <div class="card">
          <Cropper
            v-if="file"
            ref="cropper"
            :src="file"
            class="cropper"
            :stencil-props="{
            aspectRatio: 1,
          }"
          />
          <div
            class="px-4 py-3 is-flex is-justify-content-flex-end is-align-items-center"
            style="gap: 10px"
          >
            <b-button
              type="is-light"
              @click="handleCropperModalClose"
            >Отменить
            </b-button>
            <b-button
              type="is-success"
              @click="saveCroppedImage"
            >Сохранить
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { Cropper, Preview } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import SelectCMS from "@/components/CMS/SelectCMS.vue"

export default {
  components: { SelectCMS, Cropper, Preview },
  props: ['channelList'],
  data() {
    return {
      is_loading: true,
      file: undefined,
      result: {
        coordinates: null,
        image: null,
        canvas: undefined,
      },
      cropperModal: false,
      filteredTags: [],
      selectedUsers: [],
      isSelectOnly: false,
      status: null,
      tags: [],
      CmsData: [],
      cms_id: null,
      Company_list: [],
      CompanyBranches: [],
      BranchFactories: [],
      BranchUsers: [],
      UsersData: [],
      User_ids: [],
      module_count: [
        {
          id: 4,
          name: "Четыре канала",
        },
        {
          id: 8,
          name: "Восемь каналов",
        },
        {
          id: 8,
          name: "Десять каналов",
        },
        {
          id: 12,
          name: "Двенадцать каналов",
        },
      ],
      temperature_count: [
        {
          id: 0,
          name: "Отсутствует",
        },
        {
          id: 4,
          name: "Четыре канала",
        },
        {
          id: 8,
          name: "Восемь каналов",
        },
      ],
      selectedVibsCount: null,
      selectedTempCount: null,
      errors: [],
      isTrendsActive: false,
      isSpectersActive: false,
      isTrendSpectersActive: false,
      isOilsActive: false,
      isAIActive: false,
      isDKWActive: false,
      copyDialogIsVisible: false,

      isUnloading: false,
      unloadErrors: []
    };
  },
  watch: {
    "CmsData.factory.branch.company.id"(newValue) {
      this.getCompanyBranches(newValue);
    },
    "CmsData.factory.branch.id"(newValue) {
      this.getBranchesFactories(newValue);
      this.getBranchesUsers(newValue);
    },
  },
  created() {
    this.getCmsData();
    this.getCompanies();
    this.getCmsUsers();
  },
  methods: {
    getImagePath(path) {
      return `${process.env.VUE_APP_API_URL}${path}`
    },
    handleImageCrop(image) {
      if (image) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.file = e.target.result;
          this.cropperModal = true;
          this.$nextTick(() => {
            this.$refs.cropper.refresh();
          });
        };
        reader.readAsDataURL(image);
      }
    },
    async saveCroppedImage() {
      this.result = this.$refs.cropper.getResult();
      this.result.data = this.result.canvas.toDataURL("image/jpeg");
      this.result.canvas.toBlob((blob) => {
        this.result.size = blob.size;
      }, "image/jpeg");
      this.handleCropperModalClose();
    },
    handleValue(value) {
      this.selectedUsers = value.map(user => user.id);
    },
    reloadPage() {
      location.reload()
    },
    getFilteredTags(text) {
      const arr = [...this.BranchUsers];
      this.filteredTags = this.BranchUsers.filter((option) => {
        return option.first_name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0;
      });
    },

    getStatusButtonLabel(value) {
      return {
        0: 'Деактивировать',
        1: 'Активировать',
        2: 'Устройство обновляет конфигурация',
        3: 'Идет синхронизация конфигурации',
      }[value]
    },

    async changeStatus(status) {
      try {
        await this.$axios.put(`admin/agents/${this.cms_id}/status`, { status })
        this.status = status
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    onStatusButtonClick() {
      if(this.status === 0) return this.changeStatus(1)
      else if(this.status === 1) return this.changeStatus(0)
      else return false
    },

    async getCmsData() {
      this.is_loading = true
      this.cms_id = this.$router.history.current.params.id.replace(/[^0-9]/g, "");
      await this.$axios.get(`admin/agents/${this.cms_id}`).then(response => {
        this.CmsData = response.data.data;
        this.isAIActive = response.data?.data?.config?.ai
        this.isDKWActive = response.data?.data?.config?.dkw
        this.isOilsActive = response.data?.data?.config?.oils
        this.isTrendsActive = response.data?.data?.config?.trends
        this.isSpectersActive = response.data?.data?.config?.specters
        this.isTrendSpectersActive = response.data?.data?.config?.trend_specters
        this.status = response.data?.data?.status
        this.is_loading = false

        const modulesCount = response.data?.data?.module_count ?? 0
        let chList = []
        for (let i = 1; i <= modulesCount; i++) {
          chList = [...chList, `VIB${i}`]
        }
        this.$emit("update:channelList", chList)
      });
    },
    async DeleteAgent() {
      await this.$axios.delete(`/admin/agents/${this.cms_id}/`).then(response => {
        this.$router.push("/cms-list");
      });
    },
    async SaveChangesCMS() {

      let data = {
        "ip": this.CmsData.ip,
        "factory_id": this.CmsData.factory.id,
        "ip_local": this.CmsData.ip_local,
        "name": "this.CmsData.name",
        "device_name": this.CmsData.device_name,
        "login": this.CmsData.login,
        "password": this.CmsData.password,
        "sync_hour": this.CmsData.sync_hour,
        "sync_minute": this.CmsData.sync_minute,
        "user_ids": this.selectedUsers,
        "module_count": this.CmsData.module_count,
        "temperature_count": this.CmsData.temperature_count,
        "shift": this.CmsData.shift,
        "coefficient": this.CmsData.coefficient,
        "config": {
          trends: this.isTrendsActive,
          specters: this.isSpectersActive,
          trend_specters: this.isTrendSpectersActive,
          oils: this.isOilsActive,
          ai: this.isAIActive,
          dkw: this.isDKWActive
        },
        "image": {
          size: this.result.size,
          data: this.result.data,
        },
      };

      await this.$axios.put(`admin/agents/${this.cms_id}/`, data)
        .then(() => {
          this.$router.push("/cms-list");
        })
        .catch((err) => {
          this.errors = err?.error?.response?.data?.errors || [];
          console.log("ERRORS", this.errors);
        });
    },
    async getCompanies() {
      await this.$axios.get(`admin/companies`).then(response => {
        this.Company_list = response.data.data;
      });
    },
    async getCompanyBranches(e) {
      await this.$axios.get(`admin/companies/${e}/branches`).then(response => {
        this.CompanyBranches = response.data.data;
      });
    },
    async getBranchesFactories(e) {
      await this.$axios.get(`admin/branches/${e}/factories`).then(response => {
        this.BranchFactories = response.data.data;
      });
    },
    async getBranchesUsers(e) {
      await this.$axios.get(`admin/branches/${e}/users`).then(response => {
        this.BranchUsers = response.data.data;

        this.UsersData = response.data.data.filter(user => {
          return this.selectedUsers.includes(user.id);
        });
        this.filteredTags = this.BranchUsers;
      });
    },
    async getCmsUsers() {
      await this.$axios.get(`admin/agents/${this.cms_id}/users`).then(response => {
        this.selectedUsers = response.data.data.map(user => user.id);
      });
    },
    loadReports() {
      this.$router.push(`/cms-editor/${this.cms_id}/reports`);
    },
    handleCropperModalClose() {
      this.file = undefined;
      this.cropperModal = false;
    },
    deleteImage() {
      this.result = {
        coordinates: null,
        image: null,
        canvas: undefined,
      };
    },

    async unloadConfiguration() {
      this.isUnloading = true
      try {
        const { data } = await this.$axios.post(`admin/agents/${this.cms_id}/upload`)
        if(Array.isArray(data?.errors) && data?.errors?.length === 0) {
          this.$buefy.toast.open({ message: 'Успешно выгружено', type: 'is-success' })
        } else this.unloadErrors = data?.errors || []
        this.status = 2
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        this.isUnloading = false
      }
    }
  },
};
</script>

<style>
.main-box {
  overflow: scroll;
}

.heading-text-size {
  font-size: 14px;
}

.cropper {
  max-height: 500px;
}
</style>
